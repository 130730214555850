import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowFremantleLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowFremantleLogo: React.FC<PerthNowFremantleLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 48"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now Canning'}</title>
            <path
                d="M0 46.5175H5.68737V38.918H12.133V34.958H5.68737V30.3867H13.9413V26.3394H0V46.5175Z"
                fill="black"
            />
            <path
                d="M14.943 46.5175H20.0471V36.0354C20.922 35.5986 22.0887 35.3366 23.4595 35.3366C23.897 35.3366 24.4511 35.4239 24.7719 35.4822V30.9981C24.597 30.9108 24.2761 30.8525 23.8678 30.8525C22.2345 30.8525 20.8345 31.6969 20.0179 32.8907H19.8721V31.0272H14.943V46.5175Z"
                fill="black"
            />
            <path
                d="M33.8011 46.8087C36.6594 46.8087 38.5843 46.1099 39.751 45.4111V42.2373C38.5551 42.7323 36.951 43.1691 35.1136 43.1691C32.4886 43.1691 31.1178 42.4994 30.797 40.0826H40.3926V38.7433C40.3926 33.6478 38.4385 30.7361 33.2469 30.7361C28.5804 30.7361 25.5763 33.7351 25.5763 38.5394V38.918C25.5763 44.0426 28.2887 46.8087 33.8011 46.8087ZM33.2469 34.1136C34.7344 34.1136 35.4636 34.9289 35.4636 37.2001H30.7678C31.0012 34.7833 32.0803 34.1136 33.2469 34.1136Z"
                fill="black"
            />
            <path
                d="M42.0229 46.5175H47.127V35.2492C47.7686 34.958 48.5561 34.7251 49.3144 34.7251C50.5394 34.7251 51.0352 35.191 51.0352 36.3557V46.5175H56.1393V35.5986C56.1393 35.4821 56.1101 35.3657 56.1101 35.2492C56.8684 34.9289 57.5392 34.7251 58.2976 34.7251C59.5517 34.7251 60.0184 35.191 60.0184 36.3557V46.5175H65.1224V34.9289C65.1224 31.8716 63.5474 30.7361 60.8933 30.7361C59.0267 30.7361 57.2768 31.3766 55.7601 32.4831H55.6143C54.9143 31.2602 53.6893 30.7361 51.9102 30.7361C49.8978 30.7361 48.3811 31.3766 47.0978 32.3957H46.952V31.0272H42.0229V46.5175Z"
                fill="black"
            />
            <path
                d="M71.4813 46.8087C73.4062 46.8087 74.777 46.2555 75.4187 45.3237H75.5937L75.9437 46.5175H80.2019V35.4822C80.2019 31.9881 77.8395 30.7361 74.0187 30.7361C71.5979 30.7361 69.5563 31.0272 67.9522 31.6387V35.0163C69.0313 34.7542 70.5188 34.5504 72.0646 34.5504C74.3104 34.5504 75.0979 34.8998 75.0979 36.5304V37.2001L72.2687 37.5786C68.8855 38.0445 66.6397 39.1509 66.6397 42.412C66.6397 45.382 68.623 46.8087 71.4813 46.8087ZM73.2312 43.5767C72.2687 43.5767 71.5396 43.1691 71.5396 41.8879C71.5396 40.7232 72.0646 40.2282 73.2896 40.1409L75.0979 39.9662V42.9652C74.7479 43.3146 74.0187 43.5767 73.2312 43.5767Z"
                fill="black"
            />
            <path
                d="M82.4326 46.5175H87.5367V35.2492C88.1783 34.9872 89.1408 34.7251 89.9575 34.7251C91.3866 34.7251 91.9116 35.1619 91.9116 36.3848V46.5175H97.0156V34.9872C97.0156 31.9299 95.324 30.7361 92.4366 30.7361C90.4824 30.7361 88.8492 31.3184 87.5075 32.3957H87.3617V31.0272H82.4326V46.5175Z"
                fill="black"
            />
            <path
                d="M105.184 46.7505C106.817 46.7505 107.984 46.4302 108.596 46.1099V42.8197C108.188 42.907 107.604 42.9944 107.079 42.9944C105.825 42.9944 105.359 42.645 105.359 41.5676V34.5795H108.509V31.0272H105.359V27.3585H100.225V31.0272H98.3005V34.5795H100.225V42.2955C100.225 45.7022 102.063 46.7505 105.184 46.7505Z"
                fill="black"
            />
            <path
                d="M110.369 46.5175H115.473V25H110.369V46.5175Z"
                fill="black"
            />
            <path
                d="M125.408 46.8087C128.267 46.8087 130.192 46.1099 131.358 45.4111V42.2373C130.163 42.7323 128.558 43.1691 126.721 43.1691C124.096 43.1691 122.725 42.4994 122.404 40.0826H132V38.7433C132 33.6478 130.046 30.7361 124.854 30.7361C120.188 30.7361 117.184 33.7351 117.184 38.5394V38.918C117.184 44.0426 119.896 46.8087 125.408 46.8087ZM124.854 34.1136C126.342 34.1136 127.071 34.9289 127.071 37.2001H122.375C122.609 34.7833 123.688 34.1136 124.854 34.1136Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowFremantleLogo
